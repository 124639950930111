import React,{useState,useEffect} from "react";
import BackgroundImage from "gatsby-background-image";
import "../../assets/blog-types/blog1.scss";
import { BiTimeFive } from "@react-icons/all-files/bi/BiTimeFive";
import { BiShareAlt } from "@react-icons/all-files/bi/BiShareAlt";
import { AiOutlineHeart } from "@react-icons/all-files/ai/AiOutlineHeart";
import { AiFillHeart } from "@react-icons/all-files/ai/AiFillHeart";

import moment from "moment";
import {Link } from "gatsby";
import {useLocalStorage} from "react-use-storage";

import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp";


export default function Blog1(props) {
  const [value, setValue, removeValue] = useLocalStorage("key", "[]");
  const [favMap, setFavMap] = useState([]);
  const [loc, setPageURL] = useState(0);

  useEffect(() => {
    setFavMap(value);
    setPageURL(window.location.href);
  }, [])
  
  const favClick = (x)=>{
    const find = favMap.indexOf(x)
    if(find> -1){
      let a = favMap;
      a.splice(find, 1);
      setFavMap([...a]);
      setValue([...a]);
    }
    else {
      setFavMap([...favMap,x]);
      setValue([...favMap,x]);
    }
  }

  return (
    <div className={props.type===1?"blog1 blogtype1":props.type===2?"blog1 blogtype2":"blog1 blogtype3"}>
      {props.data.map((v, k) => (
        <>
          <BackgroundImage
            fluid={v.node.image.localFile.childImageSharp.fluid}
            className="blog-bg"
          > <Link key={k} className="blog-card" to={"/blog/" + v.node.slug}>

            <div className="hover">
          
              <p className="title">
                <p className="category">{v.node.category.name}</p>
                {v.node.title}
                <p className="date-pp">
                  <BiTimeFive /> 
                  {moment(v.node.createdAt).format("DD MMM YYYY")}
                </p>
              </p>
            
            </div>
            </Link>
            <a href="#"><BlogCardShare link={loc+ v.node.slug}/></a>
            <a onClick={()=>favClick(v.node.slug)}><BlogFav selected={favMap.includes(v.node.slug)}/></a>
          </BackgroundImage>
        </>
      ))}
    </div>
  );
}

const BlogFav =({selected})=>{
  return <div className="BlogCardShare" style={{top: "10px"}}>
    {selected?<AiFillHeart size={30}/>:<AiOutlineHeart size={30}/>}
  </div>
}

const BlogCardShare = ({link})=>{
  return <div className="BlogCardShare" style={{bottom: "10px"}}>
      <div className="BlogCardShare-expand">
      <Link
        to={"https://www.facebook.com/sharer.php?u=" + link}
        target="blank"><FaFacebookF/></Link>
        <Link
        to={"https://twitter.com/intent/tweet?url=" + link}
        target="blank"
      >
        <FaTwitter/></Link>
        <Link
        to={"https://www.linkedin.com/shareArticle?mini=true&url=" + link}
        target="blank"
        className="lind"
      >
        <FaLinkedinIn/>
        </Link>
        <Link
        target="blank"
        to={"https://wa.me?text=" + encodeURIComponent(link)}
        className="whats"
      >  <FaWhatsapp/>
      </Link>      
      </div>
      <BiShareAlt size={30}/>
  </div>
}