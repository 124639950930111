import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";


import Blog1 from "../../components/blog-types/blog1";

// const OldBlog = ({ daa }) => (
//   <>
//     {daa.map((v, k) => (
//       <Link key={k} className="blog-card" to={"/blog/" + v.node.slug}>
//         <div style={{ minHeight: v.node.title.length > 27 ? 270 : 245 }}>
//           <BackgroundImage
//             fluid={v.node.image.localFile.childImageSharp.fluid}
//             className="blog-bg"
//           />
//           <div className="blog-ti">{v.node.title}</div>

//           <p className="date-pp">
//             <BiTimeFive /> {moment(v.node.createdAt).format("DD MMM YYYY")} —{" "}
//             {v.node.category.name}
//           </p>
//           <p className="cat-pp">{}</p>
//         </div>
//       </Link>
//     ))}
//   </>
// );

const Blog = ({ data }) => {
  let daa = data.allStrapiArticles.edges;
  return (
    <Layout title={"Blog"}>
      <div className="blog-main">
        <h1>Blog</h1>
        <div className="wrapper_max">
          <Blog1 data={daa.slice(0,4)} type={1}/>
          <Blog1 data={daa.slice(4,6)} type={2}/>
          <Blog1 data={daa.slice(6,8)} type={3}/>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allStrapiArticles(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          slug
          title
          createdAt
          category {
            name
          }
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

export default Blog;
